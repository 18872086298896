import { createContext, useMemo } from "react";
import "./App.css";
import LandingPage from "./Landing/LandingPage";

import { serverTimestamp } from "firebase/firestore";

import { debounce } from "lodash";

// Overview
import OverviewPage from "./Landing/SurveyOverview";
import RegistrationPage from "./Landing/RegistrationPage";

// Education
import EducationPage from "./Education/EducationPage";
import WalkTime from "./Education/WalkTime";
import SidewalkCondition, {
  SidewalkConditionBad,
  SidewalkConditionModerate,
  SidewalkConditionGood,
  SidewalkConditionSummary,
} from "./Education/SidewalkCondition";
import CrimeSafety, {
  CrimeSafetyLow,
  CrimeSafetyModerate,
  CrimeSafetyHigh,
  CrimeSafetySummary,
} from "./Education/CrimeSafety";
import TrafficSafety, {
  TrafficSafetyLow,
  TrafficSafetyModerate,
  TrafficSafetyHigh,
  TrafficSafetySummary,
} from "./Education/TrafficSafety";
import ThermalComfort, {
  ThermalComfortHot,
  ThermalComfortWarm,
  ThermalComfortNeutral,
  ThermalComfortSummary,
} from "./Education/ThermalComfort";

// Quiz
import { QuizLanding } from "./Quiz/Quiz";
import Quiz1 from "./Quiz/Quiz1";
import Quiz2 from "./Quiz/Quiz2";
import Quiz3 from "./Quiz/Quiz3";
import Quiz4 from "./Quiz/Quiz4";
import QuizResults from "./Quiz/QuizResults";

// Survey
import {
  SurveyPurposefulWalks,
  SurveyRecreationalWalks,
  SurveySelection,
} from "./Survey/Survey";
import SurveyQuestion from "./Survey/SurveyQuestion";
import {
  tables_v1,
  tables_v2,
  tables_v3,
  tables_v4,
  tables_v5,
} from "./Tables";
import SurveyComplete from "./Quiz/SurveyComplete";

import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { db } from "./firebase";
import { doc, setDoc } from "firebase/firestore";
import axios from "axios";

import { GlobalProvider } from "./GlobalContext";

// Global context
export const UserContext = createContext(null);

function App() {
  const [quizScore, setQuizScore] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [transportationSurveyVersion, setPurposefulSurveyVersion] = useState(1);
  const [recreationalSurveyVersion, setRecreationalSurveyVersion] = useState(1);
  const [purposefulResponses, setPurposefulResponses] = useState([]);
  const [recreationalResponses, setRecreationalResponses] = useState([]);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [ip, setIP] = useState("");

  // A question is a pair of [Path1, Path2]. Dividing 36/2,
  // leaves us with 18 questions.
  const NUM_QUESTIONS = 18;

  // Memoize the recreationalTable directly, based on the version
  const recreationalTable = useMemo(() => {
    console.log(1);
    switch (recreationalSurveyVersion) {
      case 1:
        return tables_v1;
      case 2:
        return tables_v2;
      case 3:
        return tables_v3;
      case 4:
        return tables_v4;
      case 5:
        return tables_v5;
      default:
        throw new Error("Incorrect argument!");
    }
  }, [recreationalSurveyVersion]);

  // Similarly for the purposefulTable, if you have similar logic:
  const purposefulTable = useMemo(() => {
    console.log(2);
    switch (transportationSurveyVersion) {
      case 1:
        return tables_v1;
      case 2:
        return tables_v2;
      case 3:
        return tables_v3;
      case 4:
        return tables_v4;
      case 5:
        return tables_v5;
      default:
        throw new Error("Incorrect argument!");
    }
  }, [transportationSurveyVersion]);

  var sliderSettings = {
    swipe: false,
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    speed: 400,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const submitSurvey = async () => {
    try {
      let formattedName = name.toLowerCase().replace(/\s+/g, "_");
      let docName = (formattedName + "_" + birthdate).replaceAll("/", "-");

      await setDoc(doc(db, "survey_responses", docName), {
        name: formattedName,
        gender: gender,
        birthdate: birthdate,
        transportationVersion: transportationSurveyVersion,
        transportationResponses: purposefulResponses,
        recreationalVersion: recreationalSurveyVersion,
        recreationalResponses: recreationalResponses,
        time: serverTimestamp(),
        ip_address: ip, // Privacy concerns @John. Is this needed?
      });
      console.log("Survey successfully submitted!");
    } catch (error) {
      console.error("Error submitting survey: ", error);
    }
  };

  // Update IP address on launch
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };
  useEffect(() => {
    getData();
  }, []);

  const nextPage = debounce(() => {
    setSlideIndex(slideIndex + 1);
  }, 50);

  useEffect(() => {
    if (
      purposefulResponses.length === NUM_QUESTIONS &&
      recreationalResponses.length === NUM_QUESTIONS
    ) {
      submitSurvey();
    }
  }, [purposefulResponses, recreationalResponses]);

  const backPage = () => {
    setSlideIndex(slideIndex - 1);
  };

  const incrementQuizScore = () => {
    setQuizScore(quizScore + 1);
  };
  const toRetakeQuiz = () => {
    nextPage();
    setQuizScore(0);
  };

  const SURVEY_TYPES = {
    PURPOSEFUL: 0,
    RECREATIONAL: 1,
  };

  const setQuestionResponse = (surveyType, questionNumber, selection) => {
    if (surveyType === SURVEY_TYPES.PURPOSEFUL) {
      const newResponses = [...purposefulResponses];
      newResponses[questionNumber - 1] = selection;
      setPurposefulResponses(newResponses);
    } else if (surveyType === SURVEY_TYPES.RECREATIONAL) {
      const newResponses = [...recreationalResponses];
      newResponses[questionNumber - 1] = selection;
      setRecreationalResponses(newResponses);
    }
  };

  const setRegistrationResponse = (name, gender, birthdate) => {
    setName(name);
    setGender(gender);
    setBirthdate(birthdate);
  };

  //   Show a warning when the user tries to leave the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    sliderRef.slickGoTo(slideIndex);
  }, [slideIndex]);

  var sliderRef = useRef(null);

  return (
    <GlobalProvider>
      <div id="app">
        <Slider
          {...sliderSettings}
          ref={(slider) => {
            sliderRef = slider;
          }}
        >
          {/* [0-2] Landing Pages */}
          <LandingPage next={nextPage} setSlideIndex={setSlideIndex} />
          <OverviewPage next={nextPage} back={backPage} />
          <RegistrationPage
            next={nextPage}
            setResponse={setRegistrationResponse}
            back={backPage}
          />

          {/* [3] Education Page: intro */}
          <EducationPage
            next={nextPage}
            setSlideIndex={setSlideIndex}
            back={backPage}
          />
          {/* [4] 1. Walk Time */}
          <WalkTime next={nextPage} back={backPage} />

          {/* [5-9] 2. Sidewalk Condition */}
          <SidewalkCondition next={nextPage} back={backPage} />
          <SidewalkConditionBad next={nextPage} back={backPage} />
          <SidewalkConditionModerate next={nextPage} back={backPage} />
          <SidewalkConditionGood next={nextPage} back={backPage} />
          <SidewalkConditionSummary next={nextPage} back={backPage} />

          {/* [10-14] 3. Crime Safety */}
          <CrimeSafety next={nextPage} back={backPage} />
          <CrimeSafetyLow next={nextPage} back={backPage} />
          <CrimeSafetyModerate next={nextPage} back={backPage} />
          <CrimeSafetyHigh next={nextPage} back={backPage} />
          <CrimeSafetySummary next={nextPage} back={backPage} />

          {/* [15-19] 4. Traffic Safety */}
          <TrafficSafety next={nextPage} back={backPage} />
          <TrafficSafetyLow next={nextPage} back={backPage} />
          <TrafficSafetyModerate next={nextPage} back={backPage} />
          <TrafficSafetyHigh next={nextPage} back={backPage} />
          <TrafficSafetySummary next={nextPage} back={backPage} />

          {/* [20-24] 5. Thermal Comfort */}
          <ThermalComfort next={nextPage} back={backPage} />
          <ThermalComfortHot next={nextPage} back={backPage} />
          <ThermalComfortWarm next={nextPage} back={backPage} />
          <ThermalComfortNeutral next={nextPage} back={backPage} />
          <ThermalComfortSummary next={nextPage} back={backPage} />

          {/* [25] Quiz Landing */}
          <QuizLanding next={nextPage} back={backPage} />

          {/* [26-29] Quiz 1-5 */}
          <Quiz1 next={nextPage} incrementScore={incrementQuizScore} />
          <Quiz2 next={nextPage} incrementScore={incrementQuizScore} />
          <Quiz3 next={nextPage} incrementScore={incrementQuizScore} />
          <Quiz4 next={nextPage} incrementScore={incrementQuizScore} />

          {/* [30] Results */}
          <QuizResults
            pass={() => setSlideIndex(31)}
            fail={toRetakeQuiz}
            quizScore={quizScore}
            retake={false}
          />

          {/* [31-32] Transportation Survey */}
          <SurveyPurposefulWalks next={nextPage} />
          <SurveySelection
            next={nextPage}
            back={backPage}
            surveyVersion={transportationSurveyVersion}
            setSurveyVersion={setPurposefulSurveyVersion}
          />

          {/* [33-62] Purposeful Walk Questions */}
          {Array(NUM_QUESTIONS)
            .fill()
            .map((_, i) => {
              return (
                <SurveyQuestion
                  key={i}
                  surveyType={SURVEY_TYPES.PURPOSEFUL}
                  questionNumber={i + 1}
                  totalQuestions={NUM_QUESTIONS}
                  route1={purposefulTable[i][0]}
                  route2={purposefulTable[i][1]}
                  next={nextPage}
                  back={backPage}
                  setResponse={setQuestionResponse}
                  backDisabled={i === 0}
                />
              );
            })}

          {/* [63-64] Recreational Walks Survey */}
          <SurveyRecreationalWalks next={nextPage} />
          <SurveySelection
            next={nextPage}
            back={backPage}
            surveyVersion={recreationalSurveyVersion}
            setSurveyVersion={setRecreationalSurveyVersion}
          />

          {/* [65-94] Recreational Walk Questions */}
          {Array(NUM_QUESTIONS)
            .fill()
            .map((_, i) => {
              return (
                <SurveyQuestion
                  key={i}
                  surveyType={SURVEY_TYPES.RECREATIONAL}
                  questionNumber={i + 1}
                  totalQuestions={NUM_QUESTIONS}
                  route1={recreationalTable[i][0]}
                  route2={recreationalTable[i][1]}
                  next={nextPage}
                  back={backPage}
                  setResponse={setQuestionResponse}
                  backDisabled={i === 0}
                />
              );
            })}

          {/* [95] Survey Complete */}
          <SurveyComplete />
        </Slider>
      </div>
    </GlobalProvider>
  );
}

export default App;
