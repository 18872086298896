import { CSSProperties, useState } from "react";
import {
  BackButton,
  CloseButton,
  NextButton,
  SpanishButton,
} from "../Components/Button";
import LevelTag from "../Components/LevelTag";
import crime_low_1 from "../Assets/crime_low_1.jpg";
import crime_low_2 from "../Assets/crime_low_2.jpeg";
import crime_low_3 from "../Assets/crime_low_3.jpg";
import crime_moderate_1 from "../Assets/crime_moderate_1.jpg";
import crime_moderate_2 from "../Assets/crime_moderate_2.jpeg";
import crime_moderate_3 from "../Assets/crime_moderate_3.jpg";
import crime_high_1 from "../Assets/crime_high_1.jpg";
import crime_high_2 from "../Assets/crime_high_2.jpeg";
import crime_high_3 from "../Assets/crime_high_3.jpg";
import warning_emoji from "../Assets/warning-emoji.png";
import crime_safety from "../Assets/crime-safety.png";
import ProgressBar from "../Components/ProgressBar";
import { useGlobalContext } from "../GlobalContext";

const columnStyle: CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 10px",
  textAlign: "center",
};

const buttonStyle = {
  padding: "10px 50px",
  fontSize: "20px",
  border: "none",
  borderRadius: "300px",
  fontWeight: "bold",
  color: "white",
  margin: "0 10px",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "nowrap",
};

function CrimeSafety(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container">
      <div className="top-right-button">
        <SpanishButton />
      </div>

      {!isSpanish ? (
        <>
          <ProgressBar
            steps={[
              "Walk Time",
              "Sidewalk Condition",
              "Crime Safety",
              "Traffic Safety",
              "Thermal Comfort",
            ]}
            currentStep={3}
          />
        </>
      ) : (
        <>
          <ProgressBar
            steps={[
              "Tiempo de Caminata",
              "Condición de la Acera",
              "Seguridad en Relación al Crimen",
              "Seguridad Vial",
              "Comodidad Térmica",
            ]}
            currentStep={3}
          />
        </>
      )}

      <div className="content-container">
        <div className="text-content">
          <h1 className="emoji-header" style={{ marginBottom: "-3%" }}>
            <img
              src={warning_emoji}
              alt=""
              style={{ width: "10%", height: "auto" }}
            />
          </h1>

          {!isSpanish ? (
            <>
              <h1>3. Crime Safety</h1>
              <p>
                Crime safety demonstrates how safe a path is from criminal
                activities.
              </p>

              <p>
                There are three levels for crime safety: low safe, moderate
                safe, and high safe.
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Low safe
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Moderate safe
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  High safe
                </button>
              </div>
            </>
          ) : (
            <>
              <h1>3. en Relación al Crimen</h1>
              <p>
                La seguridad en relación al crimen indica cuán seguro es un
                camino frente a actividades delictivas.
              </p>

              <p>
                Hay tres niveles para la seguridad en relación al crimen: baja
                seguridad, seguridad moderada, alta seguridad.
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <button style={{ ...buttonStyle, backgroundColor: "#ff4d4d" }}>
                  Baja seguridad
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#ffbf00" }}>
                  Seguridad Moderada
                </button>
                <button style={{ ...buttonStyle, backgroundColor: "#00b300" }}>
                  Alta Seguridad
                </button>
              </div>
            </>
          )}
        </div>

        <div className="image-content">
          <img src={crime_safety} alt="Crime Safety" />
        </div>
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function CrimeSafetyLow(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "30%",
    margin: "0 15px",
    textAlign: "center",
  };

  return (
    <div className="landing-container" style={{ textAlign: "center" }}>
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "-5%",
          }}
        >
          {!isSpanish ? (
            <>
              {" "}
              <h2 style={{ marginRight: "10px" }}>Crime safety</h2>
              <LevelTag text="Low safe" level={1} />
            </>
          ) : (
            <>
              <h2 style={{ marginRight: "10px" }}>
                Seguridad en Relación al Crimen
              </h2>
              <LevelTag text="Baja Seguridad" level={1} />
            </>
          )}
        </div>
        <p style={{ marginBottom: "-2%" }}>
          {!isSpanish ? (
            <>Path with low crime safety has:</>
          ) : (
            <>Una acero de baja seguridad en relación al crimen tiene:</>
          )}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
        }}
      >
        {!isSpanish ? (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Prevalent</strong> physical disorders
              </p>
              <img
                src={crime_low_1}
                alt="Prevalent physical disorders"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) <strong>Violent</strong> crime record
              </p>
              <img
                src={crime_low_2}
                alt="Violent crime record"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>Some</strong> homeless in <strong>less</strong>{" "}
                crowded path
              </p>
              <img
                src={crime_low_3}
                alt="Some homeless population in less crowded path"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        ) : (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Desórdenes físicos evidentes</strong> en el camino
              </p>
              <img
                src={crime_low_1}
                alt="Prevalent physical disorders"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) Registros de <strong>delitos violentos</strong>
              </p>
              <img
                src={crime_low_2}
                alt="Violent crime record"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>Parta</strong> de la población indigente en rutas
                menos concurridas
              </p>
              <img
                src={crime_low_3}
                alt="Some homeless population in less crowded path"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        )}
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function CrimeSafetyModerate(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();
  return (
    <div className="landing-container" style={{ textAlign: "center" }}>
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "-5%",
          }}
        >
          {!isSpanish ? (
            <>
              <h2 style={{ marginRight: "10px" }}>Crime safety</h2>
              <LevelTag text="Moderate safe" level={2} />
            </>
          ) : (
            <>
              <h2 style={{ marginRight: "10px" }}>
                Seguridad en Relación al Crimen
              </h2>
              <LevelTag text="Seguridad Moderada" level={2} />
            </>
          )}
        </div>
        <p style={{ marginBottom: "-2%" }}>
          {!isSpanish ? (
            <>Path with moderate crime safety has:</>
          ) : (
            <>Una acero de seguridad moderada en relación al crimen tiene:</>
          )}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
        }}
      >
        {!isSpanish ? (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Some</strong> signs of physical disorders
              </p>
              <img
                src={crime_moderate_1}
                alt="Some signs of physical disorders"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) <strong>Non-violent</strong> crime records
              </p>
              <img
                src={crime_moderate_2}
                alt="Nonviolent crime records"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) Some homeless in <strong>crowded</strong> path
              </p>
              <img
                src={crime_moderate_3}
                alt="Homeless population in crowded path"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        ) : (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>Algunos signos</strong> de desórdenes fisicos en el
                camino
              </p>
              <img
                src={crime_moderate_1}
                alt="Some signs of physical disorders"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) Registros de <strong>delitos no violentos</strong>
              </p>
              <img
                src={crime_moderate_2}
                alt="Nonviolent crime records"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>Población indigente</strong> en una acero concurrido
              </p>
              <img
                src={crime_moderate_3}
                alt="Homeless population in crowded path"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        )}
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function CrimeSafetyHigh(props: { next: any; back: any }) {
  const { isSpanish } = useGlobalContext();

  return (
    <div className="landing-container" style={{ textAlign: "center" }}>
      <div className="top-right-button">
        <SpanishButton />
      </div>

      <div style={{ alignSelf: "flex-start", marginLeft: "4%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "-5%",
          }}
        >
          {!isSpanish ? (
            <>
              <h2 style={{ marginRight: "10px" }}>Crime safety</h2>
              <LevelTag text="High safe" level={3} />
            </>
          ) : (
            <>
              <h2 style={{ marginRight: "10px" }}>
                Seguridad en Relación al Crimen
              </h2>
              <LevelTag text="Alta Seguridad" level={3} />
            </>
          )}
        </div>

        <p style={{ marginBottom: "-2%" }}>
          {!isSpanish ? (
            <>Path with high crime safety has:</>
          ) : (
            <>Una acero de alta seguridad en relación al crimen tiene:</>
          )}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
        }}
      >
        {!isSpanish ? (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>No</strong> signs of physical disorders
              </p>
              <img
                src={crime_high_1}
                alt="No signs of physical disorders"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) <strong>No</strong> crime records
              </p>
              <img
                src={crime_high_2}
                alt="No crime records"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>No</strong> homeless population
              </p>
              <img
                src={crime_high_3}
                alt="No homeless population"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        ) : (
          <>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                1) <strong>No hay signos</strong> de desórdenes físicos en el
                camino
              </p>
              <img
                src={crime_high_1}
                alt="No signs of physical disorders"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                2) <strong>No hay registros</strong> de delitos
              </p>
              <img
                src={crime_high_2}
                alt="No crime records"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
            <div style={columnStyle}>
              <p style={{ height: "2em" }}>
                3) <strong>No hay población</strong> indigente
              </p>
              <img
                src={crime_high_3}
                alt="No homeless population"
                style={{ width: "100%", height: "auto", borderRadius: "3%" }}
              />
            </div>
          </>
        )}
      </div>

      <div className="bottom-left-button">
        <BackButton buttonAction={props.back} />
      </div>
      <div className="bottom-right-button">
        <NextButton buttonAction={props.next} />
      </div>
    </div>
  );
}

function CrimeSafetyTable() {
  const [currentImageIndex, setCurrentImageIndex] = useState([0, 0, 0]);
  const { isSpanish } = useGlobalContext();

  const columnStyle: CSSProperties = {
    width: "50%",
    margin: "10px",
    marginTop: "0px",
    padding: "10px",
    boxSizing: "border-box",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#f0f0f0",
  };

  const listStyle: CSSProperties = {
    fontSize: "1.5em",
    height: "5em",
    lineHeight: "1.2",
    marginBottom: "20px",
  };

  const images = [
    [crime_low_1, crime_low_2, crime_low_3],
    [crime_moderate_1, crime_moderate_2, crime_moderate_3],
    [crime_high_1, crime_high_2, crime_high_3],
  ];

  const handlePrevImage = (columnIndex: number) => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = [...prevIndex];
      newIndex[columnIndex] =
        (prevIndex[columnIndex] - 1 + images[columnIndex].length) %
        images[columnIndex].length;
      return newIndex;
    });
  };

  const handleNextImage = (columnIndex: number) => {
    setCurrentImageIndex((prevIndex) => {
      const newIndex = [...prevIndex];
      newIndex[columnIndex] =
        (prevIndex[columnIndex] + 1) % images[columnIndex].length;
      return newIndex;
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        marginBottom: "12%",
      }}
    >
      <div className="three-col-column" style={columnStyle}>
        {!isSpanish ? (
          <>
            <LevelTag text="Low safe" level={1} />
            <ul style={listStyle}>
              <li>
                <strong>Prevalent</strong> physical disorders
              </li>
              <li>
                <strong>Violent</strong> crime record
              </li>
              <li>
                <strong>Some</strong> homeless in <strong>less</strong> crowded
                path
              </li>
            </ul>
          </>
        ) : (
          <>
            <LevelTag text="Baja Seguridad" level={1} />
            <ul style={listStyle}>
              <li>
                <strong>Desórdenes físicos evidentes</strong> en el camino
              </li>
              <li>
                Registros de <strong>delitos violentos</strong>
              </li>
              <li>
                <strong>Parte</strong> de la población indigente en rutas menos
                concurridas
              </li>
            </ul>
          </>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handlePrevImage(0)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &lt;
          </button>
          <img
            src={images[0][currentImageIndex[0]]}
            alt="Prevalent physical disorders, violent crime record, some homeless population in less crowded path"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              margin: "10px 0",
              borderRadius: "3%",
            }}
          />
          <button
            onClick={() => handleNextImage(0)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &gt;
          </button>
        </div>
      </div>

      <div className="three-col-column" style={columnStyle}>
        {!isSpanish ? (
          <>
            <LevelTag text="Moderate safe" level={2} />

            <ul style={listStyle}>
              <li>
                <strong>Some</strong> signs of physical disorders
              </li>
              <li>
                <strong>Non-violent</strong> crime records
              </li>
              <li>
                <strong>Some</strong> homeless in a <strong>crowded</strong>{" "}
                path
              </li>
            </ul>
          </>
        ) : (
          <>
            <LevelTag text="Seguridad Moderada" level={2} />
            <ul style={listStyle}>
              <li>
                <strong>Algunos signos</strong> de desórdenes fisicos en el
                camino
              </li>
              <li>
                Registros de <strong>delitos no violentos</strong>
              </li>
              <li>
                Población indigente en un <strong>camino concurrido</strong>
              </li>
            </ul>
          </>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handlePrevImage(1)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &lt;
          </button>
          <img
            src={images[1][currentImageIndex[1]]}
            alt="Some physical disorders, non-violent crime record, homeless population in a crowded path"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              margin: "10px 0",
              borderRadius: "3%",
            }}
          />
          <button
            onClick={() => handleNextImage(1)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &gt;
          </button>
        </div>
      </div>

      <div className="three-col-column" style={columnStyle}>
        {!isSpanish ? (
          <>
            <LevelTag text="High safe" level={3} />
            <ul style={listStyle}>
              <li>
                <strong>No</strong> signs of physical disorders
              </li>
              <li>
                <strong>No</strong> crime records
              </li>
              <li>
                <strong>No</strong> homeless population
              </li>
            </ul>
          </>
        ) : (
          <>
            <LevelTag text="Alta Seguridad" level={3} />
            <ul style={listStyle}>
              <li>
                <strong>No hay signos</strong> de desórdenes físicos en el
                camino
              </li>
              <li>
                <strong>No hay registros</strong> de delitos
              </li>
              <li>
                <strong>No hay población</strong> indigente
              </li>
            </ul>
          </>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => handlePrevImage(2)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &lt;
          </button>
          <img
            src={images[2][currentImageIndex[2]]}
            alt="No physical disorders, no crime records, no homeless population"
            style={{
              height: "250px",
              width: "250px",
              objectFit: "cover",
              margin: "10px 0",
              borderRadius: "3%",
            }}
          />
          <button
            onClick={() => handleNextImage(2)}
            style={{ fontSize: "24px", padding: "5px 10px", margin: "0 20px" }}
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

function CrimeSafetySummary(props: {
  next?: any;
  back?: any;
  closeHandler?: any;
}) {
  const { isSpanish } = useGlobalContext();
  return (
    <div className="landing-container">
      {props.closeHandler && (
        <div className="top-left-button">
          <CloseButton buttonAction={props.closeHandler} />
        </div>
      )}

      <div className="top-right-button">
        <SpanishButton />
      </div>

      <h2
        style={{
          textAlign: "left",
          width: "100%",
          marginLeft: "7%",
          marginTop: "12%",
        }}
      >
        {!isSpanish ? <>Crime Safety</> : <>Seguridad en Relación al Crimen</>}
      </h2>
      <CrimeSafetyTable />
      {props.back && (
        <div className="bottom-left-button">
          <BackButton buttonAction={props.back} />
        </div>
      )}
      {props.next && (
        <div className="bottom-right-button">
          <NextButton buttonAction={props.next} />
        </div>
      )}
    </div>
  );
}

export default CrimeSafety;
export {
  CrimeSafetyLow,
  CrimeSafetyModerate,
  CrimeSafetyHigh,
  CrimeSafetySummary,
  CrimeSafetyTable,
};
